@import "_variables";

.index__container {
    /*posicion y layout*/
    background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
    width: 100%;
    height: 100vh;
    // min-height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/

    .index__div--container--logo-principal {
        /*posicion y layout*/
        position: absolute;
        top: 0;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/

        img {
            /*posicion y layout*/
            /*modelo de caja*/
            margin-top: 30px;
            /*tipografia*/
            /*visual*/
            width: 190px;
            /*misc*/
        }
    }

    .index__span--adorno-linea-blanca {
        /*posicion y layout*/
        width: 100%;
        height: 30px;
        position: absolute;
        top: 140px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-color: white;
        -webkit-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
        /*misc*/
    }

    .index__div--container-menu {
        /*posicion y layout*/
        width: 100%;
        position: relative;
        top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        z-index: 1;
        /*misc*/

        .index__button--index {
            /*posicion y layout*/
            width: 100%;
            height: 65px;
            display: flex;
            justify-content: center;
            align-items: center;
            /*modelo de caja*/
            margin: 8px 0 8px 0;
            /*tipografia*/
            font-family: $fuente-principal;
            font-style: $font-style-principal;
            font-size: 18.5px;
            /*visual*/
            color: #000000;
            border-radius: 50px;
            border: none;
            background-color: white;
            -webkit-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
            cursor: pointer;
            /*misc*/

            img {
                /*posicion y layout*/
                width: 250px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }

        .index__button--index:hover {
            /*posicion y layout*/
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            // background-color: rgb(247, 247, 247);
            -webkit-box-shadow: 0px 0px 2px 3px rgba(43, 43, 43, 0.75);
            -moz-box-shadow: 0px 0px 2px 3px rgba(43, 43, 43, 0.75);
            box-shadow: 0px 0px 2px 3px rgba(43, 43, 43, 0.75);
            /*misc*/
        }

        @media screen and (min-width: 300px) {
            .index__button--index {
                /*posicion y layout*/
                width: 80%;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }

        @media screen and (min-width: 600px) {
            .index__button--index {
                /*posicion y layout*/
                width: 360px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }
    }


    .index__div--container-marcas-asociadas {
        /*posicion y layout*/
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-color: white;
        z-index: 0;
        /*misc*/

        img {
            /*posicion y layout*/
            width: 100%;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            background-color: white;
            /*misc*/
        }

        @media screen and (min-width: 500px) {
            img {
                /*posicion y layout*/
                width: 500px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }
    }
}


@media screen and (orientation: landscape) {
    .index__container {
        /*posicion y layout*/
        min-height: 700px;
        max-height: 100vh;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}
@import "_variables";

//Abarrotes
.seccion__div--containerAbarrotes {
    /*posicion y layout*/
    background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
    width: 100%;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}


//Seccion fixed abarrotes
.seccion__div--container-logo-fixed-abarrotes {
    /*posicion y layout*/
    position: fixed;
    top: 0;
    width: 100%;
    height: 160px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background: linear-gradient(1.83deg, #87C61F -91.24%, #C9E17C 115.11%);
    /*misc*/

    .seccion__div--logo-container-abarrotes {
        /*posicion y layout*/
        position: relative;
        top: 0;
        display: flex;
        justify-content: center;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/

        img {
            /*posicion y layout*/
            /*modelo de caja*/
            margin-top: 20px;
            /*tipografia*/
            /*visual*/
            width: 190px;
            /*misc*/
        }
    }
}

//Adorno linea blanca
.seccion__span--adorno-linea-blanca {
    /*posicion y layout*/
    width: 100%;
    height: 30px;
    position: absolute;
    top: 130px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: white;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    /*misc*/
}

//seccion opciones
.seccion__div--container-opciones {
    /*posicion y layout*/
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    padding-top: 180px;
    padding-bottom: 100px;
    /*tipografia*/
    /*visual*/
    // background-color: blue;
    /*misc*/

    .seccion__a--opcion {
        /*posicion y layout*/
        width: 90%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*modelo de caja*/
        margin: 10px 0 10px 0;
        /*tipografia*/
        font-family: $fuente-principal;
        font-style: $font-style-principal;
        font-weight: 700;
        /*visual*/
        border-radius: 30px;
        border: none;
        background-color: white;
        -webkit-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        /*misc*/
        cursor: pointer;

        img {
            /*posicion y layout*/
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            user-select: none;
            pointer-events: none;
            /*misc*/
        }
    }

    @media screen and (min-width: 350px) {
        .seccion__a--opcion {
            /*posicion y layout*/
            width: 340px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}


//__________________________________________________________________
//Konfyt
.seccion__div--containerKonfyt-alta {
    /*posicion y layout*/
    background-image: url('../images/Secciones/Konfyt/Menu-konfyt-movil.png');
    width: 100%;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

@media screen and (min-width: 700px) {
    .seccion__div--containerKonfyt-alta  {
        /*posicion y layout*/
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-image: url('../images/Secciones/Konfyt/Menu-konfut-pc.png');
        /*misc*/
    }
}


.seccion__div--containerKonfyt-baja {
    /*posicion y layout*/
    background-image: url('../images/Secciones/Konfyt/Menu-konfyt-movil.png');
    width: 100%;
    align-items: center;
    height: 100vh;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

@media screen and (min-width: 700px) {
    .seccion__div--containerKonfyt-baja  {
        /*posicion y layout*/
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-image: url('../images/Secciones/Konfyt/Menu-konfut-pc.png');
        /*misc*/
    }
}


//Seccion fixed konfyt
.seccion__div--container-logo-fixed-konfyt {
    /*posicion y layout*/
    position: fixed;
    top: 0;
    width: 100%;
    height: 160px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-image: url('../images/Secciones/Konfyt/Menu-konfyt-movil.png');
    /*misc*/

    .seccion__div--logo-container-konfyt {
        /*posicion y layout*/
        position: relative;
        width: 100%;
        top: 0;
        display: flex;
        justify-content: space-around;
        /*modelo de caja*/
        margin: auto;
        /*tipografia*/
        /*visual*/
        /*misc*/

        img {
            /*posicion y layout*/
            /*modelo de caja*/
            margin-top: 20px;
            /*tipografia*/
            /*visual*/
            width: 45%;
            /*misc*/
        }
    }

    @media screen and (min-width: 450px) {
        .seccion__div--logo-container-konfyt  {
            /*posicion y layout*/
            width: 450px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/

            img {
                /*posicion y layout*/
                width: 190px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }
    }
}


//Adorno linea azul
.seccion__span--adorno-linea-azul-konfyt {
    /*posicion y layout*/
    width: 100%;
    height: 30px;
    position: absolute;
    top: 130px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: #041c7c ;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    /*misc*/
}


//seccion opciones
.seccion__div--container-opciones-konfyt {
    /*posicion y layout*/
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    padding-top: 180px;
    /*tipografia*/
    /*visual*/
    // background-color: blue;
    /*misc*/

    .seccion__a--opcion {
        /*posicion y layout*/
        width: 90%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*modelo de caja*/
        margin: 20px 0 20px 0;
        /*tipografia*/
        font-family: $fuente-principal;
        font-style: $font-style-principal;
        font-weight: 700;
        /*visual*/
        border-radius: 30px;
        border: none;
        background-color: white;
        -webkit-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        /*misc*/

        img {
            /*posicion y layout*/
            width: 245px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }

    @media screen and (min-width: 350px) {
        .seccion__a--opcion {
            /*posicion y layout*/
            width: 340px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}

@media screen and (min-width: 1000px) {
    .seccion__div--container-opciones-konfyt {
        /*posicion y layout*/
        padding-top: 175px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}

//Adorno linea roja
.seccion__span--adorno-linea-roja-konfyt {
    /*posicion y layout*/
    width: 100%;
    height: 30px;
    /*modelo de caja*/
    margin-top: 20px;
    /*tipografia*/
    /*visual*/
    background-color: #ba0a1c;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    /*misc*/
}


//_______________________________________________________________________
//aseo
.seccion__div--containerAseo {
    /*posicion y layout*/
    background-image: url('../images/Secciones/Aseo/menu-aseo.png');
    width: 100%;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.seccion__div--containerAseo-baja {
    /*posicion y layout*/
    background-image: url('../images/Secciones/Aseo/menu-aseo.png');
    width: 100%;
    align-items: center;
    height: 870px;
    background-repeat: repeat;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

//Seccion fixed abarrotes
.seccion__div--container-logo-fixed-aseo {
    /*posicion y layout*/
    position: fixed;
    top: 0;
    width: 100%;
    height: 160px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-image: url('../images/Secciones/Aseo//menu-aseo.png');
    /*misc*/

    .seccion__div--logo-container-aseo {
        /*posicion y layout*/
        position: relative;
        top: 0;
        display: flex;
        justify-content: center;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/

        img {
            /*posicion y layout*/
            /*modelo de caja*/
            margin-top: 20px;
            /*tipografia*/
            /*visual*/
            width: 190px;
            /*misc*/
        }
    }
}


//Adorno linea blanca 1
.seccion__span--adorno-linea-blanca1-aseo {
    /*posicion y layout*/
    width: 100%;
    height: 30px;
    position: absolute;
    top: 130px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: #ffffff ;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    /*misc*/
}


//seccion opciones
.seccion__div--container-opciones-aseo {
    /*posicion y layout*/
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    padding-top: 180px;
    /*tipografia*/
    /*visual*/
    // background-color: blue;
    /*misc*/

    .seccion__a--opcion {
        /*posicion y layout*/
        width: 90%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*modelo de caja*/
        margin: 30px 0 30px 0;
        /*tipografia*/
        font-family: $fuente-principal;
        font-style: $font-style-principal;
        font-weight: 700;
        /*visual*/
        border-radius: 30px;
        border: none;
        background-color: white;
        -webkit-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        /*misc*/
    }

    @media screen and (min-width: 350px) {
        .seccion__a--opcion {
            /*posicion y layout*/
            width: 340px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}

@media screen and (min-width: 1000px) {
    .seccion__div--container-opciones-aseo {
        /*posicion y layout*/
        padding-top: 175px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}

//Adorno linea blanca 2
.seccion__span--adorno-linea-blanca2-aseo {
    /*posicion y layout*/
    width: 100%;
    height: 30px;
    /*modelo de caja*/
    margin-top: 20px;
    /*tipografia*/
    /*visual*/
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    /*misc*/
}

//__________________________________________________________________
//Yupi
.seccion__div--containerYupi-alta {
    /*posicion y layout*/
    background: linear-gradient(360deg, #069bdb 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #055c9d 50%, #069bdb 99.02%);
    width: 100%;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}


.seccion__div--containerYupi-baja {
    /*posicion y layout*/
    background: linear-gradient(360deg, #069bdb 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #055c9d 50%, #069bdb 99.02%);
    width: 100%;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

@media screen and (min-width: 700px) {
    .seccion__div--containerYupi-baja  {
        background: linear-gradient(360deg, #069bdb 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #055c9d 50%, #069bdb 99.02%);
        width: 100%;
        align-items: center;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}


//Seccion fixed yupi
.seccion__div--container-logo-fixed-yupi {
    /*posicion y layout*/
    position: fixed;
    top: 0;
    width: 100%;
    height: 160px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background: linear-gradient(1.83deg, #055c9d  -91.24%,  #069bdb 115.11%);
    /*misc*/

    .seccion__div--logo-container-yupi {
        /*posicion y layout*/
        position: relative;
        width: 100%;
        top: 0;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        /*modelo de caja*/
        margin: auto;
        /*tipografia*/
        /*visual*/
        /*misc*/

        img {
            /*posicion y layout*/
            /*modelo de caja*/
            margin-top: 20px;
            /*tipografia*/
            /*visual*/
            width: 45%;
            /*misc*/
        }
    }

    @media screen and (min-width: 335px) {
        .seccion__div--logo-container-yupi  {
            /*posicion y layout*/
            width: 335px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/

            img {
                /*posicion y layout*/
                width: 150px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }
    }
}


//Adorno linea azul
.seccion__span--adorno-linea-blanca-yupi {
    /*posicion y layout*/
    width: 100%;
    height: 30px;
    position: absolute;
    top: 130px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: #ffffff ;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    /*misc*/
}


//seccion opciones
.seccion__div--container-opciones-yupi {
    /*posicion y layout*/
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    padding-top: 180px;
    /*tipografia*/
    /*visual*/
    // background-color: blue;
    /*misc*/

    .seccion__a--opcion {
        /*posicion y layout*/
        width: 90%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*modelo de caja*/
        margin: 10px 0 10px 0;
        /*tipografia*/
        font-family: $fuente-principal;
        font-style: $font-style-principal;
        font-weight: 700;
        /*visual*/
        border-radius: 30px;
        border: none;
        background-color: white;
        -webkit-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        /*misc*/

    }

    @media screen and (min-width: 350px) {
        .seccion__a--opcion {
            /*posicion y layout*/
            width: 340px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}

@media screen and (min-width: 1000px) {
    .seccion__div--container-opciones-yupi {
        /*posicion y layout*/
        padding-top: 175px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}

//Adorno linea roja
.seccion__span--adorno-linea-blanca2-yupi {
    /*posicion y layout*/
    width: 100%;
    height: 30px;
    /*modelo de caja*/
    margin-top: 20px;
    margin-bottom: 70px;
    /*tipografia*/
    /*visual*/
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    /*misc*/
}

//__________________________________________________________________
//Productos colanta
.seccion__div--containerColanta-alta {
    /*posicion y layout*/
    background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
    width: 100%;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}


.seccion__div--containerColanta-baja {
    /*posicion y layout*/
    background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
    width: 100%;
    min-height: 100vh;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

@media screen and (min-width: 700px) {
    .seccion__div--containerColanta-baja  {
        background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F, #C9E17C 99.02%);
        width: 100%;
        align-items: center;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}


//Seccion fixed yupi
.seccion__div--container-logo-fixed-colanta {
    /*posicion y layout*/
    position: fixed;
    top: 0;
    width: 100%;
    height: 160px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background: linear-gradient(1.83deg, #87C61F  -91.24%,  #C9E17C 115.11%);
    /*misc*/

    .seccion__div--logo-container-colanta {
        /*posicion y layout*/
        position: relative;
        width: 100%;
        top: 0;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        /*modelo de caja*/
        margin: auto;
        /*tipografia*/
        /*visual*/
        /*misc*/

        img {
            /*posicion y layout*/
            /*modelo de caja*/
            margin-top: 20px;
            /*tipografia*/
            /*visual*/
            width: 45%;
            /*misc*/
        }
    }

    @media screen and (min-width: 335px) {
        .seccion__div--logo-container-colanta  {
            /*posicion y layout*/
            width: 335px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/

            img {
                /*posicion y layout*/
                width: 150px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }
    }
}


//Adorno linea azul
.seccion__span--adorno-linea-blanca-colanta {
    /*posicion y layout*/
    width: 100%;
    height: 30px;
    position: absolute;
    top: 130px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: #ffffff ;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    /*misc*/
}


//seccion opciones
.seccion__div--container-opciones-colanta {
    /*posicion y layout*/
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    padding-top: 180px;
    /*tipografia*/
    /*visual*/
    // background-color: blue;
    /*misc*/

    .seccion__a--opcion {
        /*posicion y layout*/
        width: 90%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*modelo de caja*/
        margin: 10px 0 10px 0;
        /*tipografia*/
        font-family: $fuente-principal;
        font-style: $font-style-principal;
        font-weight: 700;
        /*visual*/
        border-radius: 30px;
        border: none;
        background-color: white;
        -webkit-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        /*misc*/

        .seccion__img--seccion-img {
            /*posicion y layout*/
            width: 100px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            background-color: #041c7c;
            /*misc*/
        }
    }

    @media screen and (min-width: 350px) {
        .seccion__a--opcion {
            /*posicion y layout*/
            width: 340px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}

@media screen and (min-width: 1000px) {
    .seccion__div--container-opciones-colanta {
        /*posicion y layout*/
        padding-top: 175px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}

//Adorno linea blanca baja
.seccion__span--adorno-linea-blanca2-colanta {
    /*posicion y layout*/
    width: 100%;
    height: 30px;
    /*modelo de caja*/
    margin-top: 20px;
    margin-bottom: 70px;
    /*tipografia*/
    /*visual*/
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    /*misc*/
}
@import "_variables";

.productos__div--container--colanta {
    /*posicion y layout*/
    background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
    width: 100%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

//Konfyt
.productos__div--container--konfyt {
    /*posicion y layout*/
    background-image: url('../images/Secciones/Konfyt/Menu-konfyt-movil.png');
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

@media screen and (min-width: 500px) {
    .productos__div--container--konfyt {
        /*posicion y layout*/
        background-image: url('../images/Secciones/Konfyt/Menu-konfut-pc.png');

        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}

//Konfyt
.productos__div--container--aseo {
    /*posicion y layout*/
    background-image: url('../images/Secciones/Aseo//menu-aseo.png');
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}


//Yupi
.productos__div--container--yupi {
    /*posicion y layout*/
    background: linear-gradient(360deg, #069bdb 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #055c9d 50%, #069bdb 99.02%);
    width: 100%;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}


.productos__div--logos-container {
    /*posicion y layout*/
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    top: 10px;
    /*modelo de caja*/
    margin: auto;
    /*tipografia*/
    /*visual*/
    /*misc*/

    img {
        /*posicion y layout*/
        width: 45%;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}

@media screen and (min-width: 500px) {
    .productos__div--logos-container {
        /*posicion y layout*/
        width: 400px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/

        img {
            /*posicion y layout*/
            width: 190;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}


.productos__div--container-categorias-productos {
    /*posicion y layout*/
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 50px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    // background-color: blue;
    /*misc*/
}

@media screen and (min-width: 500px) {
    .productos__div--container-categorias-productos {
        /*posicion y layout*/
        width: 500px;
        /*modelo de caja*/
        margin: auto;
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}


.productos__div--container-categoria {
    /*posicion y layout*/
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /*modelo de caja*/
    margin: 0 0 80px 0  ;
    /*tipografia*/
    /*visual*/
    // background-color: red;
    /*misc*/

    .productos__img--imagen-categoria {
        /*posicion y layout*/
        width: 90%;
        /*modelo de caja*/
        margin: auto;
        /*tipografia*/
        /*visual*/
        border-radius: 14px;
        background-color: rgb(255, 255, 255);
        /*misc*/
        -webkit-box-shadow: 0px 0px 2px 4px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 2px 4px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 2px 4px rgba(0,0,0,0.75);
    }
}


.productos__div--container-productos {
    /*posicion y layout*/
    position: relative;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    /*modelo de caja*/
    margin: 20px auto;
    /*tipografia*/
    /*visual*/
    border-radius: 14px;
    background-color: white;
    /*misc*/
    -webkit-box-shadow: 0px 0px 2px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 4px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 4px rgba(0,0,0,0.75);
}

@media screen and (min-width: 500px) {
    .productos__div--container-productos {
        /*posicion y layout*/
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}


//Productos encontrados en la busqueda
.productos__div--productos-encontrados {
    /*posicion y layout*/
    width: 90%;
    /*modelo de caja*/
    padding: 10px 0;
    border-radius: 15px;
    margin:  0 0 100px  0 ;
    /*tipografia*/
    /*visual*/
    background-color: #0b0808a2;
    /*misc*/

    .buscador__div--datoEncontrado-container{
        /*posicion y layout*/
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;
        /*modelo de caja*/
        margin: 10px auto;
        /*tipografia*/
        /*visual*/
        background-color: #ffffff;
        /*misc*/


        img {
            /*posicion y layout*/
            width: 90%;
            /*modelo de caja*/
            margin: auto;
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}



.buscador__div--container {
    /*posicion y layout*/
    display: flex;
    justify-content: center;
    align-items: center;
    position:relative;
    /*modelo de caja*/
    margin: 0 10px;
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.buscador__div--buscador-lupa {
    /*posicion y layout*/
    display: flex;
    flex-direction: row;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/

    input {
        /*posicion y layout*/
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }

    .buscador__button--cerrar-buscador {
        /*posicion y layout*/
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
        cursor: pointer;

        img {
            /*posicion y layout*/
            width: 18px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}

.buscador__button--lupa {
    /*posicion y layout*/
    display: flex;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    border: none;
    background-color: transparent;
    /*misc*/
}
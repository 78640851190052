@import "_variables";

.menu-prueba-1 {
    position: relative;
    width: 100%;
    top: 23px;
    z-index: 1;
}

.menu-prueba-2 {
    /*posicion y layout*/
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /*modelo de caja*/
    overflow: auto;
    white-space: nowrap;
    /*tipografia*/
    /*visual*/
    background-color: #ffffff;
    /*misc*/

    a {
        /*posicion y layout*/
        /*modelo de caja*/
        padding: 2px 10px;
        margin: 0 5px;
        /*tipografia*/
        font-family: $fuente-principal;
        /*visual*/
        border-radius: 10px;
        text-decoration: none;
        /*misc*/
    }

}

// .menu-prueba-2 {
//     width: 100%;
//     background-color: rgb(234, 2, 2);
//     height: 30px;
//     color: black;
//     display: flex;
//     justify-content: space-around;
//     overflow: scroll;

// }

.menu__fixed{
    position: fixed;
    top: 0;
    z-index: 5;
}


//Estilos menu lateral
#btn_menu,
.btn_menu {
	position: fixed;
	bottom: 90px;
	left: 30px;
	z-index: 5;
    background-color: rgb(255, 255, 255);
    border-radius: 100%;
    -webkit-box-shadow: 10px 10px 25px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 25px -11px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 25px -11px rgba(0,0,0,0.75);
    z-index: 201;
}


#btn_menu {
    display: none;
}




#btn_menu {
    display: none;
}


.btn_menu {
    width: 40px;
    height: 40px;
    color: #003a8c;
}


.btn_span {
    position: absolute;
    top: 12px;
    right: 5.5px;
    width: 70%;
    height: 5%;
    background: #373737;
    transition: all .3s ease;
}

.btn_span:nth-child(2) {
    top: 19px
}

.btn_span:nth-child(3) {
    top: 26px
}

#btn_menu:checked ~ .btn_menu #btn_span_1 {
    top: 20px;
    transform: rotate(45deg);
}

#btn_menu:checked ~ .btn_menu #btn_span_2{
    opacity: 0;
}

#btn_menu:checked ~ .btn_menu #btn_span_3{
    top: 20px;
    transform: rotate(-45deg);
}

.menu-lateral--container-menu {
    /*posicion y layout*/
    position: fixed;
    top: 0;
    left: 0;
    width: 0%;
    height: 100vh;
    z-index: 200;
    display: flex;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: #0000009b;
    overflow: hidden;
    /*misc*/
    transition: ease-in-out 0.5s;

    .menu-lateral__div--contenido-menu {
        /*posicion y layout*/
        width: 350px;
        display: flex;
        flex-direction: column;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-color: #f1f1f1;
        /*misc*/

        .menu-lateral__div--contenedor-logo-principal {
            /*posicion y layout*/
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            /*modelo de caja*/
            padding: 10px 0;
            /*tipografia*/
            /*visual*/
            background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
            /*misc*/

            img {
                /*posicion y layout*/
                width: 60%;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }

        .menu-lateral__div--contenedor-opciones {
            /*posicion y layout*/
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            /*modelo de caja*/
            margin: 20px 0;
            /*tipografia*/
            /*visual*/
            /*misc*/

            .menu-lateral__button--boton {
                /*posicion y layout*/
                width: 90%;
                height: 50px;
                /*modelo de caja*/
                margin: 5px 0;
                /*tipografia*/
                font-family: $fuente-principal;
                /*visual*/
                background-color: #87C61F;
                color: #ffffff;
                border: none;
                border-radius: 200px;
                /*misc*/
                cursor: pointer;
            }
        }

        .menu-lateral__div--contenedor-contactanos {
            /*posicion y layout*/
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            /*modelo de caja*/
            padding: 40px 0;
            /*tipografia*/
            /*visual*/
            /*misc*/

            button {
                /*posicion y layout*/
                width: 80%;
                height: 50px;
                /*modelo de caja*/
                /*tipografia*/
                font-family: $fuente-principal;
                /*visual*/
                color: white;
                background-color: #c61f1f;
                border-radius: 30px;
                border: none;
                /*misc*/
                cursor: pointer;
            }
        }

        .menu-lateral__div--contenedor-redes {
            /*posicion y layout*/
            display: flex;
            justify-content: center;
            align-items: center;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/

            img {
                /*posicion y layout*/
                width: 40px;
                /*modelo de caja*/
                margin: 0 5px;
                /*tipografia*/
                /*visual*/
                /*misc*/
                cursor: pointer;
            }
        }
    }
}

/*Despliegue hacia el lado*/
#btn_menu:checked ~ .menu-lateral--container-menu{
    width: 100%;
}

.menu-lateral--container-menu-desplegado {
    width: 100%
}

.menu__button--home {
    /*posicion y layout*/
    display: flex;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: transparent;
    border: none;
    cursor: pointer;
    /*misc*/

    img {
        /*posicion y layout*/
        width: 25px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}
// .prueba-producto {
//     background-color: rgba(0, 0, 0, 0.573);
//     width: 100%;
//     height: 100vh;
//     top: 0;
//     left: 0;
//     position: fixed;
//     z-index: 5;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }


.producto__div--container {
    /*posicion y layout*/
    display: flex;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    padding: 20px 5px;
    /*tipografia*/
    text-align: center;
    /*visual*/
    // background-color: red;
    /*misc*/
}

.producto__div--tarjeta {
    /*posicion y layout*/
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/

    img {
        /*posicion y layout*/
        position: relative;
        z-index: 1;
        width: 100%;
        object-fit: contain;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}

// @media screen and (max-width: 417px){
//     .producto__div--tarjeta {
//         /*posicion y layout*/
//         /*modelo de caja*/
//         /*tipografia*/
//         /*visual*/
//         /*misc*/

//         img {
//             /*posicion y layout*/
//             width: 140px;
//             /*modelo de caja*/
//             /*tipografia*/
//             /*visual*/
//             /*misc*/
//         }
//     }
// }

// @media screen and (max-width: 400px){
//     .producto__div--tarjeta {
//         /*posicion y layout*/
//         /*modelo de caja*/
//         /*tipografia*/
//         /*visual*/
//         /*misc*/

//         img {
//             /*posicion y layout*/
//             width: 120px;
//             /*modelo de caja*/
//             /*tipografia*/
//             /*visual*/
//             /*misc*/
//         }
//     }
// }



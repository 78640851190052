

.producto-elegido__div--container {
    /*posicion y layout*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.638);
    z-index: 10000000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.producto-elegido__div--tarjeta {
    /*posicion y layout*/
    width: 85%;
    height: 540px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    border-radius: 14px;
    background-color: white;
    /*misc*/

    button {
        /*posicion y layout*/
        position: absolute;
        top: 30px;
        right: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        /*modelo de caja*/
        padding: 10px;
        /*tipografia*/
        /*visual*/
        background-color: white;
        border-radius: 50%;
        /*misc*/
        cursor: pointer;

        img {
            /*posicion y layout*/
            width: 15px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }

    img {
        /*posicion y layout*/
        width: 100%;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}

@media screen and (min-width: 500px) {
    .producto-elegido__div--tarjeta {
        /*posicion y layout*/
        width: 450px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        border-radius: 14px;
        background-color: white;
        /*misc*/

        button {
            /*posicion y layout*/
            right: 10%;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}

@media screen and (max-height: 500px) {
    .producto-elegido__div--container {
        /*posicion y layout*/
        width: 100%;
        height: 700px;
        overflow-y: scroll;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}
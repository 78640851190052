@import "_variables";

.footer__div--container {
    /*posicion y layout*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    border-top: rgb(77, 255, 0) 4px solid;
    background-color: rgb(22, 22, 22);
    /*misc*/

    .footer__div--container-seccion1 {
        /*posicion y layout*/
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/

        .footer__div--container-subseccion1 {
            /*posicion y layout*/
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/

            button {
                /*posicion y layout*/
                /*modelo de caja*/
                margin: 20px 0;
                padding: 5px 20px;
                /*tipografia*/
                font-family: $fuente-principal;
                /*visual*/
                border-radius: 14px;
                background-color: rgb(227, 0, 0);
                color: white;
                border: none;
                /*misc*/
                cursor: pointer;
            }
        }

        .footer__div--container-nombre {
            /*posicion y layout*/
            /*modelo de caja*/
            /*tipografia*/
            font-family: $fuente-principal;
            font-size: 25px;
            /*visual*/
            border-bottom: 2px solid white ;
            color: white;
            /*misc*/
        }

        .footer__div--container-subseccion2 {
            /*posicion y layout*/
            width: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/

            h2 {
                /*posicion y layout*/
                /*modelo de caja*/
                /*tipografia*/
                font-family: $fuente-principal;
                /*visual*/
                color: white;
                /*misc*/
            }

            .footer__div--opciones-menu {
                /*posicion y layout*/
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/

                button {
                    /*posicion y layout*/
                    width: 100%;
                    /*modelo de caja*/
                    padding: 4px 0px;
                    margin: 5px 0;
                    /*tipografia*/
                    font-family: $fuente-principal;
                    /*visual*/
                    border-radius: 4px;
                    background-color: rgba(0, 226, 0, 0.216);
                    color: white;
                    border: none;
                    /*misc*/
                    cursor: pointer;
                }
            }
        }

        .footer__div--container-subseccion3 {
            /*posicion y layout*/
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/

            h2 {
                /*posicion y layout*/
                /*modelo de caja*/
                /*tipografia*/
                font-family: $fuente-principal;
                /*visual*/
                color: white;
                /*misc*/
            }

            button {
                /*posicion y layout*/
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                background-color: transparent;
                border: none;
                /*misc*/
            }

            img {
                /*posicion y layout*/
                width: 50px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
                cursor: pointer;
            }
        }
    }

    @media screen and (min-width: 1100px) {
        .footer__div--container-seccion1 {
            /*posicion y layout*/
            flex-direction: row;
            justify-content: space-around;
            align-items: first baseline;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/

            .footer__div--container-subseccion {
                /*posicion y layout*/
                /*modelo de caja*/
                margin: 0px 50px;
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }
    }

    .footer__div--container-seccion2 {
        /*posicion y layout*/
        position: relative;
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*modelo de caja*/
        margin-top: 10px;
        /*tipografia*/
        /*visual*/
        // background-color: white;
        background-image: url("../images/Footer/fondo-footer.jpg");
        background-position: center;
        background-size: cover;
        /*misc*/

        .footer__div--linea {
            /*posicion y layout*/
            position: absolute;
            width: 100%;
            height: 1.8px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            background-color: rgb(0, 0, 0);
            /*misc*/
        }

        .footer__div--linea-alta {
            /*posicion y layout*/
            top: 5px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }

        .footer__div--linea-media-izq {
            /*posicion y layout*/
            width: 10%;
            left: 0px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }

        .footer__div--linea-media-der {
            /*posicion y layout*/
            width: 10%;
            right: 0px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }

        @media screen and (min-width: 300px){
            .footer__div--linea-media-izq {
                /*posicion y layout*/
                width: 5px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }

            .footer__div--linea-media-der {
                /*posicion y layout*/
                width: 5px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }

        @media screen and (min-width: 370px){
            .footer__div--linea-media-izq {
                /*posicion y layout*/
                width: 20px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }

            .footer__div--linea-media-der {
                /*posicion y layout*/
                width: 20px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }

        @media screen and (min-width: 480px){
            .footer__div--linea-media-izq {
                /*posicion y layout*/
                width: 10%;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }

            .footer__div--linea-media-der {
                /*posicion y layout*/
                width: 10%;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }

        @media screen and (min-width: 700px){
            .footer__div--linea-media-izq {
                /*posicion y layout*/
                width: 25%;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }

            .footer__div--linea-media-der {
                /*posicion y layout*/
                width: 25%;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }

        .footer__div--linea-baja {
            /*posicion y layout*/
            bottom: 5px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }

        .footer__div--container--logos {
            /*posicion y layout*/
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            /*modelo de caja*/
            /*tipografia*/
            font-family: $fuente-principal;
            font-style: italic;
            font-weight: 100;
            /*visual*/
            /*misc*/

            .footer__div--container-disenador {
                /*posicion y layout*/
                display: flex;
                align-items: center;
                justify-content: space-around;
                /*modelo de caja*/
                margin: 10px;
                /*tipografia*/
                /*visual*/
                /*misc*/

                img {
                    /*posicion y layout*/
                    width: 30px;
                    /*modelo de caja*/
                    margin-left: 5px;
                    border-radius: 15PX;
                    /*tipografia*/
                    /*visual*/
                    /*misc*/
                    cursor: pointer;
                }

                @media screen and (min-width: 350px){
                    img{
                        /*posicion y layout*/
                        width: 50px;
                        /*modelo de caja*/
                        /*tipografia*/
                        /*visual*/
                        /*misc*/
                    }
                }
            }
            .footer__div--container-programador {
                /*posicion y layout*/
                display: flex;
                align-items: center;
                justify-content: space-around;
                /*modelo de caja*/
                margin: 10px;
                /*tipografia*/
                /*visual*/
                /*misc*/

                img {
                    /*posicion y layout*/
                    width: 30px;
                    /*modelo de caja*/
                    margin-left: 5px;
                    border-radius: 15PX;
                    /*tipografia*/
                    /*visual*/
                    /*misc*/
                    cursor: pointer;
                }

                @media screen and (min-width: 350px){
                    img{
                        /*posicion y layout*/
                        width: 50px;
                        /*modelo de caja*/
                        /*tipografia*/
                        /*visual*/
                        /*misc*/
                    }
                }
            }
        }

        @media screen and (min-width: 300px) {
            .footer__div--container--logos {
                /*posicion y layout*/
                flex-direction: row;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }
    }

    @media screen and (min-width: 300px) {
        .footer__div--container-seccion2 {
            /*posicion y layout*/
            height: 80PX;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}


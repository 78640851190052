body {
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
}

html {
  height: fill-available;
  height: -webkit-fill-available;
}

.index__container {
  /*posicion y layout*/
  background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.index__container .index__div--container--logo-principal {
  /*posicion y layout*/
  position: absolute;
  top: 0;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.index__container .index__div--container--logo-principal img {
  /*posicion y layout*/
  /*modelo de caja*/
  margin-top: 30px;
  /*tipografia*/
  /*visual*/
  width: 190px;
  /*misc*/
}
.index__container .index__span--adorno-linea-blanca {
  /*posicion y layout*/
  width: 100%;
  height: 30px;
  position: absolute;
  top: 140px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: white;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}
.index__container .index__div--container-menu {
  /*posicion y layout*/
  width: 100%;
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  z-index: 1;
  /*misc*/
}
.index__container .index__div--container-menu .index__button--index {
  /*posicion y layout*/
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  margin: 8px 0 8px 0;
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-size: 18.5px;
  /*visual*/
  color: #000000;
  border-radius: 50px;
  border: none;
  background-color: white;
  -webkit-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  /*misc*/
}
.index__container .index__div--container-menu .index__button--index img {
  /*posicion y layout*/
  width: 250px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.index__container .index__div--container-menu .index__button--index:hover {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  -webkit-box-shadow: 0px 0px 2px 3px rgba(43, 43, 43, 0.75);
  -moz-box-shadow: 0px 0px 2px 3px rgba(43, 43, 43, 0.75);
  box-shadow: 0px 0px 2px 3px rgba(43, 43, 43, 0.75);
  /*misc*/
}
@media screen and (min-width: 300px) {
  .index__container .index__div--container-menu .index__button--index {
    /*posicion y layout*/
    width: 80%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (min-width: 600px) {
  .index__container .index__div--container-menu .index__button--index {
    /*posicion y layout*/
    width: 360px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.index__container .index__div--container-marcas-asociadas {
  /*posicion y layout*/
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: white;
  z-index: 0;
  /*misc*/
}
.index__container .index__div--container-marcas-asociadas img {
  /*posicion y layout*/
  width: 100%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: white;
  /*misc*/
}
@media screen and (min-width: 500px) {
  .index__container .index__div--container-marcas-asociadas img {
    /*posicion y layout*/
    width: 500px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

@media screen and (orientation: landscape) {
  .index__container {
    /*posicion y layout*/
    min-height: 700px;
    max-height: 100vh;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.seccion__div--containerAbarrotes {
  /*posicion y layout*/
  background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
  width: 100%;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.seccion__div--container-logo-fixed-abarrotes {
  /*posicion y layout*/
  position: fixed;
  top: 0;
  width: 100%;
  height: 160px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background: linear-gradient(1.83deg, #87C61F -91.24%, #C9E17C 115.11%);
  /*misc*/
}
.seccion__div--container-logo-fixed-abarrotes .seccion__div--logo-container-abarrotes {
  /*posicion y layout*/
  position: relative;
  top: 0;
  display: flex;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.seccion__div--container-logo-fixed-abarrotes .seccion__div--logo-container-abarrotes img {
  /*posicion y layout*/
  /*modelo de caja*/
  margin-top: 20px;
  /*tipografia*/
  /*visual*/
  width: 190px;
  /*misc*/
}

.seccion__span--adorno-linea-blanca {
  /*posicion y layout*/
  width: 100%;
  height: 30px;
  position: absolute;
  top: 130px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: white;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}

.seccion__div--container-opciones {
  /*posicion y layout*/
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  padding-top: 180px;
  padding-bottom: 100px;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.seccion__div--container-opciones .seccion__a--opcion {
  /*posicion y layout*/
  width: 90%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  margin: 10px 0 10px 0;
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 700;
  /*visual*/
  border-radius: 30px;
  border: none;
  background-color: white;
  -webkit-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
  cursor: pointer;
}
.seccion__div--container-opciones .seccion__a--opcion img {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  user-select: none;
  pointer-events: none;
  /*misc*/
}
@media screen and (min-width: 350px) {
  .seccion__div--container-opciones .seccion__a--opcion {
    /*posicion y layout*/
    width: 340px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

.seccion__div--containerKonfyt-alta {
  /*posicion y layout*/
  background-image: url("../images/Secciones/Konfyt/Menu-konfyt-movil.png");
  width: 100%;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

@media screen and (min-width: 700px) {
  .seccion__div--containerKonfyt-alta {
    /*posicion y layout*/
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-image: url("../images/Secciones/Konfyt/Menu-konfut-pc.png");
    /*misc*/
  }
}
.seccion__div--containerKonfyt-baja {
  /*posicion y layout*/
  background-image: url("../images/Secciones/Konfyt/Menu-konfyt-movil.png");
  width: 100%;
  align-items: center;
  height: 100vh;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

@media screen and (min-width: 700px) {
  .seccion__div--containerKonfyt-baja {
    /*posicion y layout*/
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-image: url("../images/Secciones/Konfyt/Menu-konfut-pc.png");
    /*misc*/
  }
}
.seccion__div--container-logo-fixed-konfyt {
  /*posicion y layout*/
  position: fixed;
  top: 0;
  width: 100%;
  height: 160px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-image: url("../images/Secciones/Konfyt/Menu-konfyt-movil.png");
  /*misc*/
}
.seccion__div--container-logo-fixed-konfyt .seccion__div--logo-container-konfyt {
  /*posicion y layout*/
  position: relative;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-around;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.seccion__div--container-logo-fixed-konfyt .seccion__div--logo-container-konfyt img {
  /*posicion y layout*/
  /*modelo de caja*/
  margin-top: 20px;
  /*tipografia*/
  /*visual*/
  width: 45%;
  /*misc*/
}
@media screen and (min-width: 450px) {
  .seccion__div--container-logo-fixed-konfyt .seccion__div--logo-container-konfyt {
    /*posicion y layout*/
    width: 450px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .seccion__div--container-logo-fixed-konfyt .seccion__div--logo-container-konfyt img {
    /*posicion y layout*/
    width: 190px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

.seccion__span--adorno-linea-azul-konfyt {
  /*posicion y layout*/
  width: 100%;
  height: 30px;
  position: absolute;
  top: 130px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #041c7c;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}

.seccion__div--container-opciones-konfyt {
  /*posicion y layout*/
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  padding-top: 180px;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.seccion__div--container-opciones-konfyt .seccion__a--opcion {
  /*posicion y layout*/
  width: 90%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  margin: 20px 0 20px 0;
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 700;
  /*visual*/
  border-radius: 30px;
  border: none;
  background-color: white;
  -webkit-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}
.seccion__div--container-opciones-konfyt .seccion__a--opcion img {
  /*posicion y layout*/
  width: 245px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
@media screen and (min-width: 350px) {
  .seccion__div--container-opciones-konfyt .seccion__a--opcion {
    /*posicion y layout*/
    width: 340px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

@media screen and (min-width: 1000px) {
  .seccion__div--container-opciones-konfyt {
    /*posicion y layout*/
    padding-top: 175px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.seccion__span--adorno-linea-roja-konfyt {
  /*posicion y layout*/
  width: 100%;
  height: 30px;
  /*modelo de caja*/
  margin-top: 20px;
  /*tipografia*/
  /*visual*/
  background-color: #ba0a1c;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}

.seccion__div--containerAseo {
  /*posicion y layout*/
  background-image: url("../images/Secciones/Aseo/menu-aseo.png");
  width: 100%;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.seccion__div--containerAseo-baja {
  /*posicion y layout*/
  background-image: url("../images/Secciones/Aseo/menu-aseo.png");
  width: 100%;
  align-items: center;
  height: 870px;
  background-repeat: repeat;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.seccion__div--container-logo-fixed-aseo {
  /*posicion y layout*/
  position: fixed;
  top: 0;
  width: 100%;
  height: 160px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-image: url("../images/Secciones/Aseo//menu-aseo.png");
  /*misc*/
}
.seccion__div--container-logo-fixed-aseo .seccion__div--logo-container-aseo {
  /*posicion y layout*/
  position: relative;
  top: 0;
  display: flex;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.seccion__div--container-logo-fixed-aseo .seccion__div--logo-container-aseo img {
  /*posicion y layout*/
  /*modelo de caja*/
  margin-top: 20px;
  /*tipografia*/
  /*visual*/
  width: 190px;
  /*misc*/
}

.seccion__span--adorno-linea-blanca1-aseo {
  /*posicion y layout*/
  width: 100%;
  height: 30px;
  position: absolute;
  top: 130px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}

.seccion__div--container-opciones-aseo {
  /*posicion y layout*/
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  padding-top: 180px;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.seccion__div--container-opciones-aseo .seccion__a--opcion {
  /*posicion y layout*/
  width: 90%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  margin: 30px 0 30px 0;
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 700;
  /*visual*/
  border-radius: 30px;
  border: none;
  background-color: white;
  -webkit-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}
@media screen and (min-width: 350px) {
  .seccion__div--container-opciones-aseo .seccion__a--opcion {
    /*posicion y layout*/
    width: 340px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

@media screen and (min-width: 1000px) {
  .seccion__div--container-opciones-aseo {
    /*posicion y layout*/
    padding-top: 175px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.seccion__span--adorno-linea-blanca2-aseo {
  /*posicion y layout*/
  width: 100%;
  height: 30px;
  /*modelo de caja*/
  margin-top: 20px;
  /*tipografia*/
  /*visual*/
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}

.seccion__div--containerYupi-alta {
  /*posicion y layout*/
  background: linear-gradient(360deg, #069bdb 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #055c9d 50%, #069bdb 99.02%);
  width: 100%;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.seccion__div--containerYupi-baja {
  /*posicion y layout*/
  background: linear-gradient(360deg, #069bdb 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #055c9d 50%, #069bdb 99.02%);
  width: 100%;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

@media screen and (min-width: 700px) {
  .seccion__div--containerYupi-baja {
    background: linear-gradient(360deg, #069bdb 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #055c9d 50%, #069bdb 99.02%);
    width: 100%;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.seccion__div--container-logo-fixed-yupi {
  /*posicion y layout*/
  position: fixed;
  top: 0;
  width: 100%;
  height: 160px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background: linear-gradient(1.83deg, #055c9d -91.24%, #069bdb 115.11%);
  /*misc*/
}
.seccion__div--container-logo-fixed-yupi .seccion__div--logo-container-yupi {
  /*posicion y layout*/
  position: relative;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.seccion__div--container-logo-fixed-yupi .seccion__div--logo-container-yupi img {
  /*posicion y layout*/
  /*modelo de caja*/
  margin-top: 20px;
  /*tipografia*/
  /*visual*/
  width: 45%;
  /*misc*/
}
@media screen and (min-width: 335px) {
  .seccion__div--container-logo-fixed-yupi .seccion__div--logo-container-yupi {
    /*posicion y layout*/
    width: 335px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .seccion__div--container-logo-fixed-yupi .seccion__div--logo-container-yupi img {
    /*posicion y layout*/
    width: 150px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

.seccion__span--adorno-linea-blanca-yupi {
  /*posicion y layout*/
  width: 100%;
  height: 30px;
  position: absolute;
  top: 130px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}

.seccion__div--container-opciones-yupi {
  /*posicion y layout*/
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  padding-top: 180px;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.seccion__div--container-opciones-yupi .seccion__a--opcion {
  /*posicion y layout*/
  width: 90%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  margin: 10px 0 10px 0;
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 700;
  /*visual*/
  border-radius: 30px;
  border: none;
  background-color: white;
  -webkit-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}
@media screen and (min-width: 350px) {
  .seccion__div--container-opciones-yupi .seccion__a--opcion {
    /*posicion y layout*/
    width: 340px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

@media screen and (min-width: 1000px) {
  .seccion__div--container-opciones-yupi {
    /*posicion y layout*/
    padding-top: 175px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.seccion__span--adorno-linea-blanca2-yupi {
  /*posicion y layout*/
  width: 100%;
  height: 30px;
  /*modelo de caja*/
  margin-top: 20px;
  margin-bottom: 70px;
  /*tipografia*/
  /*visual*/
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}

.seccion__div--containerColanta-alta {
  /*posicion y layout*/
  background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
  width: 100%;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.seccion__div--containerColanta-baja {
  /*posicion y layout*/
  background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
  width: 100%;
  min-height: 100vh;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

@media screen and (min-width: 700px) {
  .seccion__div--containerColanta-baja {
    background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F, #C9E17C 99.02%);
    width: 100%;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.seccion__div--container-logo-fixed-colanta {
  /*posicion y layout*/
  position: fixed;
  top: 0;
  width: 100%;
  height: 160px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background: linear-gradient(1.83deg, #87C61F -91.24%, #C9E17C 115.11%);
  /*misc*/
}
.seccion__div--container-logo-fixed-colanta .seccion__div--logo-container-colanta {
  /*posicion y layout*/
  position: relative;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.seccion__div--container-logo-fixed-colanta .seccion__div--logo-container-colanta img {
  /*posicion y layout*/
  /*modelo de caja*/
  margin-top: 20px;
  /*tipografia*/
  /*visual*/
  width: 45%;
  /*misc*/
}
@media screen and (min-width: 335px) {
  .seccion__div--container-logo-fixed-colanta .seccion__div--logo-container-colanta {
    /*posicion y layout*/
    width: 335px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .seccion__div--container-logo-fixed-colanta .seccion__div--logo-container-colanta img {
    /*posicion y layout*/
    width: 150px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

.seccion__span--adorno-linea-blanca-colanta {
  /*posicion y layout*/
  width: 100%;
  height: 30px;
  position: absolute;
  top: 130px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}

.seccion__div--container-opciones-colanta {
  /*posicion y layout*/
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  padding-top: 180px;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.seccion__div--container-opciones-colanta .seccion__a--opcion {
  /*posicion y layout*/
  width: 90%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  margin: 10px 0 10px 0;
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 700;
  /*visual*/
  border-radius: 30px;
  border: none;
  background-color: white;
  -webkit-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}
.seccion__div--container-opciones-colanta .seccion__a--opcion .seccion__img--seccion-img {
  /*posicion y layout*/
  width: 100px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #041c7c;
  /*misc*/
}
@media screen and (min-width: 350px) {
  .seccion__div--container-opciones-colanta .seccion__a--opcion {
    /*posicion y layout*/
    width: 340px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

@media screen and (min-width: 1000px) {
  .seccion__div--container-opciones-colanta {
    /*posicion y layout*/
    padding-top: 175px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.seccion__span--adorno-linea-blanca2-colanta {
  /*posicion y layout*/
  width: 100%;
  height: 30px;
  /*modelo de caja*/
  margin-top: 20px;
  margin-bottom: 70px;
  /*tipografia*/
  /*visual*/
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}

.colanta-menu__div--container-alta {
  /*posicion y layout*/
  background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
  width: 100%;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.colanta-menu__div--container-logo-fixed {
  /*posicion y layout*/
  position: fixed;
  top: 0;
  width: 100%;
  height: 160px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background: linear-gradient(1.83deg, #87C61F -91.24%, #C9E17C 115.11%);
  /*misc*/
}
.colanta-menu__div--container-logo-fixed .colanta-menu__div--logo-container {
  /*posicion y layout*/
  position: relative;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
  cursor: pointer;
}
.colanta-menu__div--container-logo-fixed .colanta-menu__div--logo-container img {
  /*posicion y layout*/
  /*modelo de caja*/
  margin-top: 20px;
  /*tipografia*/
  /*visual*/
  width: 45%;
  /*misc*/
}
@media screen and (min-width: 335px) {
  .colanta-menu__div--container-logo-fixed .colanta-menu__div--logo-container {
    /*posicion y layout*/
    width: 335px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .colanta-menu__div--container-logo-fixed .colanta-menu__div--logo-container img {
    /*posicion y layout*/
    width: 150px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

.colanta-menu__span--adorno-linea-blanca1 {
  /*posicion y layout*/
  width: 100%;
  height: 30px;
  position: absolute;
  top: 130px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: white;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}

.colanta-menu__div--container-baja {
  /*posicion y layout*/
  background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
  width: 100%;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

@media screen and (min-width: 700px) {
  .colanta-menu__div--container-baja {
    background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
    width: 100%;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.colanta-menu__div--container-opciones {
  /*posicion y layout*/
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  padding-top: 180px;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.colanta-menu__div--container-opciones button {
  /*posicion y layout*/
  width: 90%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  margin: 10px 0 10px 0;
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 700;
  /*visual*/
  border-radius: 30px;
  border: none;
  background-color: white;
  -webkit-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
  cursor: pointer;
}
.colanta-menu__div--container-opciones button img {
  /*posicion y layout*/
  width: 225px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
@media screen and (min-width: 350px) {
  .colanta-menu__div--container-opciones button {
    /*posicion y layout*/
    width: 340px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

.colanta-menu__span--adorno-linea-blanca2 {
  /*posicion y layout*/
  width: 100%;
  height: 30px;
  /*modelo de caja*/
  margin-top: 20px;
  margin-bottom: 70px;
  /*tipografia*/
  /*visual*/
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75);
  /*misc*/
}

.productos__div--container--colanta {
  /*posicion y layout*/
  background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
  width: 100%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.productos__div--container--konfyt {
  /*posicion y layout*/
  background-image: url("../images/Secciones/Konfyt/Menu-konfyt-movil.png");
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

@media screen and (min-width: 500px) {
  .productos__div--container--konfyt {
    /*posicion y layout*/
    background-image: url("../images/Secciones/Konfyt/Menu-konfut-pc.png");
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.productos__div--container--aseo {
  /*posicion y layout*/
  background-image: url("../images/Secciones/Aseo//menu-aseo.png");
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.productos__div--container--yupi {
  /*posicion y layout*/
  background: linear-gradient(360deg, #069bdb 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #055c9d 50%, #069bdb 99.02%);
  width: 100%;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.productos__div--logos-container {
  /*posicion y layout*/
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: 10px;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.productos__div--logos-container img {
  /*posicion y layout*/
  width: 45%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

@media screen and (min-width: 500px) {
  .productos__div--logos-container {
    /*posicion y layout*/
    width: 400px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .productos__div--logos-container img {
    /*posicion y layout*/
    width: 190;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.productos__div--container-categorias-productos {
  /*posicion y layout*/
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 50px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

@media screen and (min-width: 500px) {
  .productos__div--container-categorias-productos {
    /*posicion y layout*/
    width: 500px;
    /*modelo de caja*/
    margin: auto;
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.productos__div--container-categoria {
  /*posicion y layout*/
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /*modelo de caja*/
  margin: 0 0 80px 0;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.productos__div--container-categoria .productos__img--imagen-categoria {
  /*posicion y layout*/
  width: 90%;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  border-radius: 14px;
  background-color: rgb(255, 255, 255);
  /*misc*/
  -webkit-box-shadow: 0px 0px 2px 4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 2px 4px rgba(0, 0, 0, 0.75);
}

.productos__div--container-productos {
  /*posicion y layout*/
  position: relative;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  /*modelo de caja*/
  margin: 20px auto;
  /*tipografia*/
  /*visual*/
  border-radius: 14px;
  background-color: white;
  /*misc*/
  -webkit-box-shadow: 0px 0px 2px 4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 2px 4px rgba(0, 0, 0, 0.75);
}

@media screen and (min-width: 500px) {
  .productos__div--container-productos {
    /*posicion y layout*/
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.productos__div--productos-encontrados {
  /*posicion y layout*/
  width: 90%;
  /*modelo de caja*/
  padding: 10px 0;
  border-radius: 15px;
  margin: 0 0 100px 0;
  /*tipografia*/
  /*visual*/
  background-color: rgba(11, 8, 8, 0.6352941176);
  /*misc*/
}
.productos__div--productos-encontrados .buscador__div--datoEncontrado-container {
  /*posicion y layout*/
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  /*modelo de caja*/
  margin: 10px auto;
  /*tipografia*/
  /*visual*/
  background-color: #ffffff;
  /*misc*/
}
.productos__div--productos-encontrados .buscador__div--datoEncontrado-container img {
  /*posicion y layout*/
  width: 90%;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.menu-prueba-1 {
  position: relative;
  width: 100%;
  top: 23px;
  z-index: 1;
}

.menu-prueba-2 {
  /*posicion y layout*/
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /*modelo de caja*/
  overflow: auto;
  white-space: nowrap;
  /*tipografia*/
  /*visual*/
  background-color: #ffffff;
  /*misc*/
}
.menu-prueba-2 a {
  /*posicion y layout*/
  /*modelo de caja*/
  padding: 2px 10px;
  margin: 0 5px;
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  /*visual*/
  border-radius: 10px;
  text-decoration: none;
  /*misc*/
}

.menu__fixed {
  position: fixed;
  top: 0;
  z-index: 5;
}

#btn_menu,
.btn_menu {
  position: fixed;
  bottom: 90px;
  left: 30px;
  z-index: 5;
  background-color: rgb(255, 255, 255);
  border-radius: 100%;
  -webkit-box-shadow: 10px 10px 25px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 25px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 25px -11px rgba(0, 0, 0, 0.75);
  z-index: 201;
}

#btn_menu {
  display: none;
}

#btn_menu {
  display: none;
}

.btn_menu {
  width: 40px;
  height: 40px;
  color: #003a8c;
}

.btn_span {
  position: absolute;
  top: 12px;
  right: 5.5px;
  width: 70%;
  height: 5%;
  background: #373737;
  transition: all 0.3s ease;
}

.btn_span:nth-child(2) {
  top: 19px;
}

.btn_span:nth-child(3) {
  top: 26px;
}

#btn_menu:checked ~ .btn_menu #btn_span_1 {
  top: 20px;
  transform: rotate(45deg);
}

#btn_menu:checked ~ .btn_menu #btn_span_2 {
  opacity: 0;
}

#btn_menu:checked ~ .btn_menu #btn_span_3 {
  top: 20px;
  transform: rotate(-45deg);
}

.menu-lateral--container-menu {
  /*posicion y layout*/
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 100vh;
  z-index: 200;
  display: flex;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: rgba(0, 0, 0, 0.6078431373);
  overflow: hidden;
  /*misc*/
  transition: ease-in-out 0.5s;
}
.menu-lateral--container-menu .menu-lateral__div--contenido-menu {
  /*posicion y layout*/
  width: 350px;
  display: flex;
  flex-direction: column;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #f1f1f1;
  /*misc*/
}
.menu-lateral--container-menu .menu-lateral__div--contenido-menu .menu-lateral__div--contenedor-logo-principal {
  /*posicion y layout*/
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*modelo de caja*/
  padding: 10px 0;
  /*tipografia*/
  /*visual*/
  background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
  /*misc*/
}
.menu-lateral--container-menu .menu-lateral__div--contenido-menu .menu-lateral__div--contenedor-logo-principal img {
  /*posicion y layout*/
  width: 60%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.menu-lateral--container-menu .menu-lateral__div--contenido-menu .menu-lateral__div--contenedor-opciones {
  /*posicion y layout*/
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*modelo de caja*/
  margin: 20px 0;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.menu-lateral--container-menu .menu-lateral__div--contenido-menu .menu-lateral__div--contenedor-opciones .menu-lateral__button--boton {
  /*posicion y layout*/
  width: 90%;
  height: 50px;
  /*modelo de caja*/
  margin: 5px 0;
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  /*visual*/
  background-color: #87C61F;
  color: #ffffff;
  border: none;
  border-radius: 200px;
  /*misc*/
  cursor: pointer;
}
.menu-lateral--container-menu .menu-lateral__div--contenido-menu .menu-lateral__div--contenedor-contactanos {
  /*posicion y layout*/
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  padding: 40px 0;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.menu-lateral--container-menu .menu-lateral__div--contenido-menu .menu-lateral__div--contenedor-contactanos button {
  /*posicion y layout*/
  width: 80%;
  height: 50px;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  /*visual*/
  color: white;
  background-color: #c61f1f;
  border-radius: 30px;
  border: none;
  /*misc*/
  cursor: pointer;
}
.menu-lateral--container-menu .menu-lateral__div--contenido-menu .menu-lateral__div--contenedor-redes {
  /*posicion y layout*/
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.menu-lateral--container-menu .menu-lateral__div--contenido-menu .menu-lateral__div--contenedor-redes img {
  /*posicion y layout*/
  width: 40px;
  /*modelo de caja*/
  margin: 0 5px;
  /*tipografia*/
  /*visual*/
  /*misc*/
  cursor: pointer;
}

/*Despliegue hacia el lado*/
#btn_menu:checked ~ .menu-lateral--container-menu {
  width: 100%;
}

.menu-lateral--container-menu-desplegado {
  width: 100%;
}

.menu__button--home {
  /*posicion y layout*/
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: transparent;
  border: none;
  cursor: pointer;
  /*misc*/
}
.menu__button--home img {
  /*posicion y layout*/
  width: 25px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.producto__div--container {
  /*posicion y layout*/
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  padding: 20px 5px;
  /*tipografia*/
  text-align: center;
  /*visual*/
  /*misc*/
}

.producto__div--tarjeta {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.producto__div--tarjeta img {
  /*posicion y layout*/
  position: relative;
  z-index: 1;
  width: 100%;
  object-fit: contain;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.buscador__div--container {
  /*posicion y layout*/
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /*modelo de caja*/
  margin: 0 10px;
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.buscador__div--buscador-lupa {
  /*posicion y layout*/
  display: flex;
  flex-direction: row;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.buscador__div--buscador-lupa input {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.buscador__div--buscador-lupa .buscador__button--cerrar-buscador {
  /*posicion y layout*/
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
  cursor: pointer;
}
.buscador__div--buscador-lupa .buscador__button--cerrar-buscador img {
  /*posicion y layout*/
  width: 18px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.buscador__button--lupa {
  /*posicion y layout*/
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  border: none;
  background-color: transparent;
  /*misc*/
}

.fondoIlustrado__img--adorno--leche-en-polvo {
  /*posicion y layout*/
  position: absolute;
  z-index: 0;
  width: 100%;
  top: 250px;
  margin: auto;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--adorno-lecheUHT {
  /*posicion y layout*/
  position: absolute;
  z-index: 0;
  width: 50%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--lecheUHT-adorno1 {
  /*posicion y layout*/
  top: 100px;
  right: 0;
  width: 80%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--lecheUHT-adorno2 {
  /*posicion y layout*/
  top: 500px;
  left: 0;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--lecheUHT-adorno3 {
  /*posicion y layout*/
  top: 800px;
  right: 0;
  width: 90%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--adorno-cunas-varios {
  /*posicion y layout*/
  position: absolute;
  z-index: 0;
  width: 50%;
  opacity: 80%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--cunas-varios-adorno1 {
  /*posicion y layout*/
  top: 500px;
  left: 0;
  right: 0;
  margin: auto;
  width: 70%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--adorno-parmesano {
  /*posicion y layout*/
  position: absolute;
  z-index: 0;
  width: 50%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--parmesano-adorno1 {
  /*posicion y layout*/
  top: 450px;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--adorno-mozzarella {
  /*posicion y layout*/
  position: absolute;
  z-index: 0;
  width: 50%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--mozzarella-adorno1 {
  /*posicion y layout*/
  top: 100px;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  opacity: 80%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--adorno-crema-de-leche {
  /*posicion y layout*/
  position: absolute;
  z-index: 0;
  width: 50%;
  opacity: 95%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--crema-de-leche-adorno1 {
  /*posicion y layout*/
  top: 0px;
  left: 0;
  width: 60%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--crema-de-leche-adorno2 {
  /*posicion y layout*/
  top: 225px;
  right: 0;
  width: 60%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--adorno-quesos-madurados {
  /*posicion y layout*/
  position: absolute;
  z-index: 0;
  width: 50%;
  opacity: 80%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--quesos-madurados-adorno1 {
  /*posicion y layout*/
  top: 200px;
  left: 0;
  width: 80%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--quesos-madurados-adorno2 {
  /*posicion y layout*/
  top: 650px;
  right: 0;
  width: 60%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--adorno-untables {
  /*posicion y layout*/
  position: absolute;
  z-index: 0;
  width: 50%;
  opacity: 70%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--untables-adorno1 {
  /*posicion y layout*/
  top: 1300px;
  right: 0;
  width: 80%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--untables-adorno2 {
  /*posicion y layout*/
  top: 200px;
  left: 0;
  width: 80%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--untables-adorno3 {
  /*posicion y layout*/
  top: 500px;
  right: 0;
  width: 80%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--untables-adorno4 {
  /*posicion y layout*/
  top: 900px;
  left: 0;
  width: 80%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--adorno-avena {
  /*posicion y layout*/
  position: absolute;
  z-index: 0;
  width: 50%;
  opacity: 60%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.fondoIlustrado__img--avena-adorno1 {
  /*posicion y layout*/
  top: 180px;
  right: 0;
  left: 0;
  margin: auto;
  width: 80%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.producto-elegido__div--container {
  /*posicion y layout*/
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.638);
  z-index: 10000000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.producto-elegido__div--tarjeta {
  /*posicion y layout*/
  width: 85%;
  height: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  border-radius: 14px;
  background-color: white;
  /*misc*/
}
.producto-elegido__div--tarjeta button {
  /*posicion y layout*/
  position: absolute;
  top: 30px;
  right: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  padding: 10px;
  /*tipografia*/
  /*visual*/
  background-color: white;
  border-radius: 50%;
  /*misc*/
  cursor: pointer;
}
.producto-elegido__div--tarjeta button img {
  /*posicion y layout*/
  width: 15px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.producto-elegido__div--tarjeta img {
  /*posicion y layout*/
  width: 100%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

@media screen and (min-width: 500px) {
  .producto-elegido__div--tarjeta {
    /*posicion y layout*/
    width: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    border-radius: 14px;
    background-color: white;
    /*misc*/
  }
  .producto-elegido__div--tarjeta button {
    /*posicion y layout*/
    right: 10%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (max-height: 500px) {
  .producto-elegido__div--container {
    /*posicion y layout*/
    width: 100%;
    height: 700px;
    overflow-y: scroll;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.footer__div--container {
  /*posicion y layout*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  border-top: rgb(77, 255, 0) 4px solid;
  background-color: rgb(22, 22, 22);
  /*misc*/
}
.footer__div--container .footer__div--container-seccion1 {
  /*posicion y layout*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.footer__div--container .footer__div--container-seccion1 .footer__div--container-subseccion1 {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.footer__div--container .footer__div--container-seccion1 .footer__div--container-subseccion1 button {
  /*posicion y layout*/
  /*modelo de caja*/
  margin: 20px 0;
  padding: 5px 20px;
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  /*visual*/
  border-radius: 14px;
  background-color: rgb(227, 0, 0);
  color: white;
  border: none;
  /*misc*/
  cursor: pointer;
}
.footer__div--container .footer__div--container-seccion1 .footer__div--container-nombre {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  /*visual*/
  border-bottom: 2px solid white;
  color: white;
  /*misc*/
}
.footer__div--container .footer__div--container-seccion1 .footer__div--container-subseccion2 {
  /*posicion y layout*/
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.footer__div--container .footer__div--container-seccion1 .footer__div--container-subseccion2 h2 {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  /*visual*/
  color: white;
  /*misc*/
}
.footer__div--container .footer__div--container-seccion1 .footer__div--container-subseccion2 .footer__div--opciones-menu {
  /*posicion y layout*/
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.footer__div--container .footer__div--container-seccion1 .footer__div--container-subseccion2 .footer__div--opciones-menu button {
  /*posicion y layout*/
  width: 100%;
  /*modelo de caja*/
  padding: 4px 0px;
  margin: 5px 0;
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  /*visual*/
  border-radius: 4px;
  background-color: rgba(0, 226, 0, 0.216);
  color: white;
  border: none;
  /*misc*/
  cursor: pointer;
}
.footer__div--container .footer__div--container-seccion1 .footer__div--container-subseccion3 {
  /*posicion y layout*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.footer__div--container .footer__div--container-seccion1 .footer__div--container-subseccion3 h2 {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  /*visual*/
  color: white;
  /*misc*/
}
.footer__div--container .footer__div--container-seccion1 .footer__div--container-subseccion3 button {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: transparent;
  border: none;
  /*misc*/
}
.footer__div--container .footer__div--container-seccion1 .footer__div--container-subseccion3 img {
  /*posicion y layout*/
  width: 50px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
  cursor: pointer;
}
@media screen and (min-width: 1100px) {
  .footer__div--container .footer__div--container-seccion1 {
    /*posicion y layout*/
    flex-direction: row;
    justify-content: space-around;
    align-items: first baseline;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .footer__div--container .footer__div--container-seccion1 .footer__div--container-subseccion {
    /*posicion y layout*/
    /*modelo de caja*/
    margin: 0px 50px;
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.footer__div--container .footer__div--container-seccion2 {
  /*posicion y layout*/
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  margin-top: 10px;
  /*tipografia*/
  /*visual*/
  background-image: url("../images/Footer/fondo-footer.jpg");
  background-position: center;
  background-size: cover;
  /*misc*/
}
.footer__div--container .footer__div--container-seccion2 .footer__div--linea {
  /*posicion y layout*/
  position: absolute;
  width: 100%;
  height: 1.8px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: rgb(0, 0, 0);
  /*misc*/
}
.footer__div--container .footer__div--container-seccion2 .footer__div--linea-alta {
  /*posicion y layout*/
  top: 5px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.footer__div--container .footer__div--container-seccion2 .footer__div--linea-media-izq {
  /*posicion y layout*/
  width: 10%;
  left: 0px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.footer__div--container .footer__div--container-seccion2 .footer__div--linea-media-der {
  /*posicion y layout*/
  width: 10%;
  right: 0px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
@media screen and (min-width: 300px) {
  .footer__div--container .footer__div--container-seccion2 .footer__div--linea-media-izq {
    /*posicion y layout*/
    width: 5px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .footer__div--container .footer__div--container-seccion2 .footer__div--linea-media-der {
    /*posicion y layout*/
    width: 5px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (min-width: 370px) {
  .footer__div--container .footer__div--container-seccion2 .footer__div--linea-media-izq {
    /*posicion y layout*/
    width: 20px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .footer__div--container .footer__div--container-seccion2 .footer__div--linea-media-der {
    /*posicion y layout*/
    width: 20px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (min-width: 480px) {
  .footer__div--container .footer__div--container-seccion2 .footer__div--linea-media-izq {
    /*posicion y layout*/
    width: 10%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .footer__div--container .footer__div--container-seccion2 .footer__div--linea-media-der {
    /*posicion y layout*/
    width: 10%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (min-width: 700px) {
  .footer__div--container .footer__div--container-seccion2 .footer__div--linea-media-izq {
    /*posicion y layout*/
    width: 25%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .footer__div--container .footer__div--container-seccion2 .footer__div--linea-media-der {
    /*posicion y layout*/
    width: 25%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.footer__div--container .footer__div--container-seccion2 .footer__div--linea-baja {
  /*posicion y layout*/
  bottom: 5px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.footer__div--container .footer__div--container-seccion2 .footer__div--container--logos {
  /*posicion y layout*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 100;
  /*visual*/
  /*misc*/
}
.footer__div--container .footer__div--container-seccion2 .footer__div--container--logos .footer__div--container-disenador {
  /*posicion y layout*/
  display: flex;
  align-items: center;
  justify-content: space-around;
  /*modelo de caja*/
  margin: 10px;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.footer__div--container .footer__div--container-seccion2 .footer__div--container--logos .footer__div--container-disenador img {
  /*posicion y layout*/
  width: 30px;
  /*modelo de caja*/
  margin-left: 5px;
  border-radius: 15PX;
  /*tipografia*/
  /*visual*/
  /*misc*/
  cursor: pointer;
}
@media screen and (min-width: 350px) {
  .footer__div--container .footer__div--container-seccion2 .footer__div--container--logos .footer__div--container-disenador img {
    /*posicion y layout*/
    width: 50px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.footer__div--container .footer__div--container-seccion2 .footer__div--container--logos .footer__div--container-programador {
  /*posicion y layout*/
  display: flex;
  align-items: center;
  justify-content: space-around;
  /*modelo de caja*/
  margin: 10px;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.footer__div--container .footer__div--container-seccion2 .footer__div--container--logos .footer__div--container-programador img {
  /*posicion y layout*/
  width: 30px;
  /*modelo de caja*/
  margin-left: 5px;
  border-radius: 15PX;
  /*tipografia*/
  /*visual*/
  /*misc*/
  cursor: pointer;
}
@media screen and (min-width: 350px) {
  .footer__div--container .footer__div--container-seccion2 .footer__div--container--logos .footer__div--container-programador img {
    /*posicion y layout*/
    width: 50px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (min-width: 300px) {
  .footer__div--container .footer__div--container-seccion2 .footer__div--container--logos {
    /*posicion y layout*/
    flex-direction: row;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (min-width: 300px) {
  .footer__div--container .footer__div--container-seccion2 {
    /*posicion y layout*/
    height: 80PX;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}




//Estilos leche en polvo

.fondoIlustrado__img--adorno--leche-en-polvo {
    /*posicion y layout*/
    position: absolute;
    z-index: 0;
    width: 100%;
    top: 250px;
    margin: auto;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}




//Estilos leche UHT adornos
.fondoIlustrado__img--adorno-lecheUHT  {
    /*posicion y layout*/
    position: absolute;
    z-index: 0;
    width: 50%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--lecheUHT-adorno1 {
    /*posicion y layout*/
    top: 100px;
    right: 0;
    width: 80%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--lecheUHT-adorno2 {
    /*posicion y layout*/
    top: 500px;
    left: 0;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--lecheUHT-adorno3 {
    /*posicion y layout*/
    top: 800px;
    right: 0;
    width: 90%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}


//Estilos cuñas y varios adornos
.fondoIlustrado__img--adorno-cunas-varios {
    /*posicion y layout*/
    position: absolute;
    z-index: 0;
    width: 50%;
    opacity: 80%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--cunas-varios-adorno1 {
    /*posicion y layout*/
    top: 500px;
    left: 0;
    right: 0;
    margin: auto;
    width: 70%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}


//Estilos cuñas y varios adornos
.fondoIlustrado__img--adorno-parmesano {
    /*posicion y layout*/
    position: absolute;
    z-index: 0;
    width: 50%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--parmesano-adorno1 {
    /*posicion y layout*/
    top: 450px;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

//Estilos mozzarella adornos
.fondoIlustrado__img--adorno-mozzarella {
    /*posicion y layout*/
    position: absolute;
    z-index: 0;
    width: 50%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--mozzarella-adorno1 {
    /*posicion y layout*/
    top: 100px;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    opacity: 80%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

//Estilos leche UHT adornos
.fondoIlustrado__img--adorno-crema-de-leche  {
    /*posicion y layout*/
    position: absolute;
    z-index: 0;
    width: 50%;
    opacity: 95%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--crema-de-leche-adorno1 {
    /*posicion y layout*/
    top: 0px;
    left: 0;
    width: 60%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--crema-de-leche-adorno2 {
    /*posicion y layout*/
    top: 225px;
    right: 0;
    width: 60%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}


//Queso madurado
.fondoIlustrado__img--adorno-quesos-madurados  {
    /*posicion y layout*/
    position: absolute;
    z-index: 0;
    width: 50%;
    opacity: 80%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--quesos-madurados-adorno1 {
    /*posicion y layout*/
    top: 200px;
    left: 0;
    width: 80%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--quesos-madurados-adorno2 {
    /*posicion y layout*/
    top: 650px;
    right: 0;
    width: 60%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}


//Untables y mantequillas
.fondoIlustrado__img--adorno-untables  {
    /*posicion y layout*/
    position: absolute;
    z-index: 0;
    width: 50%;
    opacity: 70%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--untables-adorno1 {
    /*posicion y layout*/
    top: 1300px;
    right: 0;
    width: 80%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--untables-adorno2 {
    /*posicion y layout*/
    top: 200px;
    left: 0;
    width: 80%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--untables-adorno3 {
    /*posicion y layout*/
    top: 500px;
    right: 0;
    width: 80%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--untables-adorno4 {
    /*posicion y layout*/
    top: 900px;
    left: 0;
    width: 80%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

//Avena
.fondoIlustrado__img--adorno-avena {
    /*posicion y layout*/
    position: absolute;
    z-index: 0;
    width: 50%;
    opacity: 60%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.fondoIlustrado__img--avena-adorno1 {
    /*posicion y layout*/
    top: 180px;
    right: 0;
    left: 0;
    margin: auto;
    width: 80%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}
@import "_variables";

.colanta-menu__div--container-alta {
        /*posicion y layout*/
        background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
        width: 100%;
        align-items: center;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
}

.colanta-menu__div--container-logo-fixed {
    /*posicion y layout*/
    position: fixed;
    top: 0;
    width: 100%;
    height: 160px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background: linear-gradient(1.83deg, #87C61F -91.24%, #C9E17C 115.11%);
    /*misc*/

    .colanta-menu__div--logo-container {
        /*posicion y layout*/
        position: relative;
        width: 100%;
        top: 0;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        /*modelo de caja*/
        margin: auto;
        /*tipografia*/
        /*visual*/
        /*misc*/
        cursor: pointer;

        img {
            /*posicion y layout*/
            /*modelo de caja*/
            margin-top: 20px;
            /*tipografia*/
            /*visual*/
            width: 45%;
            /*misc*/
        }
    }

    @media screen and (min-width: 335px) {
        .colanta-menu__div--logo-container {
            /*posicion y layout*/
            width: 335px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/

            img {
                /*posicion y layout*/
                width: 150px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }
    }
}

.colanta-menu__span--adorno-linea-blanca1 {
    /*posicion y layout*/
    width: 100%;
    height: 30px;
    position: absolute;
    top: 130px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: white;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    /*misc*/
}

.colanta-menu__div--container-baja {
    /*posicion y layout*/
    background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
    width: 100%;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

@media screen and (min-width: 700px) {
    .colanta-menu__div--container-baja {
        background: linear-gradient(360deg, #C9E17C 1.74%, rgba(135, 198, 31, 0) 50%), linear-gradient(1.14deg, #87C61F 50%, #C9E17C 99.02%);
        width: 100%;
        align-items: center;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}


.colanta-menu__div--container-opciones {
    /*posicion y layout*/
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    padding-top: 180px;
    /*tipografia*/
    /*visual*/
    // background-color: blue;
    /*misc*/

    button {
        /*posicion y layout*/
        width: 90%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*modelo de caja*/
        margin: 10px 0 10px 0;
        /*tipografia*/
        font-family: $fuente-principal;
        font-style: $font-style-principal;
        font-weight: 700;
        /*visual*/
        border-radius: 30px;
        border: none;
        background-color: white;
        -webkit-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.75);
        /*misc*/
        cursor: pointer;

        img {
            /*posicion y layout*/
            width: 225px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }


    @media screen and (min-width: 350px) {
        button {
            /*posicion y layout*/
            width: 340px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}

.colanta-menu__span--adorno-linea-blanca2 {
    /*posicion y layout*/
    width: 100%;
    height: 30px;
    /*modelo de caja*/
    margin-top: 20px;
    margin-bottom: 70px;
    /*tipografia*/
    /*visual*/
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.75);
    /*misc*/
}